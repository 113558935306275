import ApiService from "@/core/services/api.service";

export const GetCart = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`cart`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteCart = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`cart/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetAssetServices = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`asset/${uuid}/services`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ProceedCart = (refreshedList) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`cart/processe`, { refreshed: refreshedList })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetOrders = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`orders`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetOrder = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`order/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ApproveOrderItems = (uuid, data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`order/${uuid}/approve`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const SendOrderEmail = (uuid, data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`order/${uuid}/send-mail`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
